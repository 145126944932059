export default (authGuard, guestGuard) => [
    ...guestGuard([
        {
            path: '/login',
            name: 'Login',
            component: () => import ('@/pages/login')
        }
    ]),
    
    ...authGuard([
        {
            path: '/user-selector',
            name: 'UserSelector',
            component: () => import ('@/pages/user-selector')
        },
        {
            path: '/',
            name: 'Home',
            component: () => import ('@/pages/home')
        },
        {
            path: '/deposit',
            name: 'Deposit',
            component: () => import ('@/pages/deposit')
        },
        {
            path: '/movements',
            name: 'Movements',
            component: () => import ('@/pages/movements')
        },
        {
            path: '/services/:id',
            name: 'Service',
            component: () => import ('@/pages/service')
        },
        {
            path: '/feedback',
            name: 'Feedback',
            component: () => import ('@/pages/feedback')
        },
    ])
];
