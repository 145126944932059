<template>
    <div 
        class="px-3 py-3 fixed inset-0 flex pointer-events-none"
        :class="{ 'pointer-events-auto': alerts.length > 0 }"
    >   
        <Transition 
            mode="out-in"
            move-class="transform transition-all duration-300"
            enter-active-class="transform transition-all duration-300 ease-out"
            leave-active-class="transform transition-all duration-300 ease-out"
            enter-from-class="opacity-0"
            enter-to-class=""
            leave-from-class=""
            leave-to-class="opacity-0"
        >
            
            <div v-if="alerts.length > 0" class="absolute inset-0 bg-gray-900 bg-opacity-50"></div>
        </Transition>

        <div class="relative w-full m-auto pointer-events-auto">
            <TransitionGroup 
                mode="out-in"
                move-class="transform transition-all duration-300 z-0"
                enter-active-class="transform transition-all duration-300 ease-out"
                leave-active-class="transform transition-all duration-300 ease-out"
                enter-from-class="scale-75 opacity-0"
                enter-to-class="scale-100"
                leave-from-class="scale-100"
                leave-to-class="scale-150 opacity-0"
            >
                <div 
                    v-for="(alert, index) in alerts.slice(0, limit)"
                    :key="alert.id"
                    class="absolute first:relative w-full top-0"
                    :style="{
                        'margin-top': `${index * -32}px`,
                        'z-index': `${alerts.length - index}`
                    }"
                >
                    <div 
                        class="px-4 py-6 bg-white rounded-2xl shadow-xl transform transition-all duration-100 ease-out" 
                        :style="{
                            'transform': `scale(${index * -0.1 + 1})`,
                        }"
                    >
                        <div>
                            <p class="text-lg text-primary font-semibold text-center">{{ alert.title }}</p>
                            <p class="mt-1 text-sm text-secondary text-center">{{ alert.text }}</p>

                            <div class="mt-6">
                                <button 
                                    v-if="alert.confirmButton !== false"
                                    @click="confirm(alert)" 
                                    class="px-3 py-2 block w-full text-white bg-gray-700 rounded-xl"
                                    :class="{ 'opacity-50': alert.loading }"
                                    :disabled="alert.loading"
                                >
                                    {{ alert.confirmLabel || 'Accept' }}
                                </button>

                                <button 
                                    v-if="alert.cancelButton !== false"
                                    class="mt-2 px-3 py-2 block w-full text-secondary rounded-xl border"
                                    @click="remove(alert)"
                                     
                                >
                                    {{ alert.cancelLabel || 'Cancel' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionGroup>
        </div>
        
    </div>
</template>

<script>
    import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
    import uuid from '@/utils/uuid.js';
    import emitter from '@/utils/emitter.js';
   
    export default defineComponent({
        name: 'Alerts',
        props: {
            limit: {
                type: Number,
                default: 5
            }
        },
        setup(props) {
            const alerts = ref([]);
            function add(props) {
                const alert = {
                    id: uuid(),
                    ...props,
                };
                alerts.value.unshift(alert);
            }
            async function confirm(alert) {
                if (alert.confirm instanceof Function) {
                    try {
                        alert.loading = true;
                        await alert.confirm({
                            close: () => remove(alert)
                        });
                        remove(alert);
                    } catch (error) {
                        
                    } finally {
                        alert.loading = false;
                    }
                }
            }
            function remove(alert) {
                const index = alerts.value.findIndex((item) => item.id === alert.id);
                if (index >= 0) {
                    alerts.value.splice(index, 1);
                }
            }
            
            onMounted(() => {
                function handler(alert) {
                    add(alert);
                };
                
                emitter.on('alerts:add', handler);

                onUnmounted(() => {
                    emitter.off('alerts:add', handler);
                });
            });
            
            return {
                alerts,
                add,
                confirm,
                remove
            };
        }
    });
</script>

<style scoped>
    .z-100 {
        z-index: 100;
    }
</style>