<template>
    <router-view />

    <Notifications />

    <Alerts />
</template>

<script>
    import { Notifications } from '@/components/notifications';
    import { Alerts } from '@/components/alerts';

    export default {
        name: 'App',
        components: {
            Notifications,
            Alerts
        }
    };
</script>

