import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes.js';

import Auth from '@/api/endpoints/Auth.js';
import { state, actions } from '@/store';

const router = createRouter({
    history: createWebHistory(),
    routes: routes(authGuard, guestGuard)
});

export default router;


router.beforeEach(async (to, from, next) => {
    // query token
    const token = to.query.token;
    if (token) {
        actions.setToken(token);
    }

    // query back subdomain
    const backSubdomain = to.query.backSubdomain;
    if (backSubdomain) {
        actions.setBackSubdomain(backSubdomain);
    }

    // query stream subdomain
    const streamSubdomain = to.query.streamSubdomain;
    if (streamSubdomain) {
        actions.setStreamSubdomain(streamSubdomain);
    }
    
    if (state.token && !state.user) {
        try {
            const users = await Auth.me();
            actions.setUsers(users); 

            if (users.length === 1) {
                actions.setUser(users[0]);
            } else {
                if (state.lastLoggedUser) {
                    const user = users.find(u => u.id == state.lastLoggedUser);
                    actions.setUser(user);
                } else {
                    next({ name: 'UserSelector' });
                    return;
                }
            }
        } catch(error) {            
            if (error?.response?.data?.error?.status === 401) {
                actions.setToken(null);
				next({ name: 'Login' })
                actions.resetAuth(null);
			}
        }
    }
    
    next();
});

function beforeEnter (routes, callback) {
    return routes.map(route => {
        return { ...route, beforeEnter: callback };
    });
};

function authGuard (routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (!state.token) {
            actions.setForward(to);
            return next({ name: 'Login' });
        }

        next();
    });
};

function guestGuard (routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (state.token) {
            return next({ name: 'Home' });
        }

        next();
    });
};

