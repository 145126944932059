import { reactive } from 'vue';
import { toLocal } from './storages';

const state = reactive({
    token: null,
    users: [],
    user: null,
    lastLoggedUser: null,
    forward: null,
    backSubdomain: process.env.VUE_APP_API_SUBDOMAIN,
	streamSubdomain: process.env.VUE_APP_STREAM_SUBDOMAIN,
});

toLocal(state, {
    namespace: 'zpay',
    attrs: [
        'token', 
        'backSubdomain',
	    'streamSubdomain',
        'lastLoggedUser',
    ],
});

const actions = {
    setToken(token) {
        state.token = token;
    },
    setUser(user) {
        state.user = user;
        state.lastLoggedUser = user.id;
    },
    setUsers(users) {
        state.users = users;
        state.user = users[0];
    },
    resetAuth() {
        state.token = null;
        state.user = null;
        state.users = [];
        state.lastLoggedUser = null;
    },
    setForward(route) {
        state.forward = route;
    },
    setBackSubdomain(back) {
		if (!back) {
			return;
		}

		state.backSubdomain = back
	},
	setStreamSubdomain(stream) {
		if (!stream) {
			return;
		}

		state.streamSubdomain = stream
	},
};

export {
    state,
    actions
};