import Endpoint from './Endpoint'
import { state } from '/src/store'

class EndpointApi extends Endpoint {
	baseUrl() {
		return `https://${state.backSubdomain}
			.${process.env.VUE_APP_API_DOMAIN}
			/${process.env.VUE_APP_API_URL}`
	}

	beforeRequest(request) {
        request.headers = { 
            ...request.headers, 
            Authorization: `Bearer ${state.token}`,
            ESID: state.user && state.user.id
        };
        return request;
    }
}

export default EndpointApi
