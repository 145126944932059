import Notifications from './Notifications.vue';
import emitter from '@/utils/emitter.js';

function notify(n) {
    emitter.emit('notifications:add', n);
}

export {
    Notifications,
    notify
};