import Service from "./Service";
import axios from 'axios';

const config = { baseUrl: process.env.VUE_APP_API_URL };
const http = axios.create(config);

import { useRouter } from 'vue-router';
import { state, actions } from '@/store';

import { notify } from '@/components/notifications';



class Endpoint extends Service {
    makeRequest({ method, url, data, headers, query }) {
        return http({
            method,
            url,
            data,
            headers,
            params: query
        });
    }

    afterRequest(response, request) {
        if (request.action === 'paginate') {
            return response.data;
        }

        return response.data.data;
    }

    async failedRequest(error) {
        const { status } = error.response;

        // Error 401: Unauthorized
        if (status === 401) {
            console.log('#Error 401: Unauthorized');

            notify({
                title: 'Error 401',
                text: 'Unauthorized'
            });
        }

        // Error 403: Forbidden
        if (status === 403) {
            console.log('#Error 403: Forbidden');
            notify({
                title: 'Error 403',
                text: 'Forbidden'
            });
        }

        // Error 404: Not found
        if (status === 404) {
            console.log('#Error 404: Not found');

            notify({
                title: 'Error 404',
                text: 'Not found'
            });
        }

        // Error 419: Token mismatch
        if (status === 419) {
            console.log('#Error 419: Authentication Timeout');

            notify({
                title: 'Error 419',
                text: 'Authentication Timeout'
            });
        }

        // Error 422: Unprocessable entity
        if (status === 422) {
            console.log('#Error 422: Unprocessable entity');

            notify({
                title: 'Error 422',
                text: 'Unprocessable entity'
            });
        }

        // Error 500: Server error
        if (status >= 500) {
            console.log('#Error 500: Server error');

            notify({
                title: 'Error 500',
                text: 'Server error'
            });
        }

        return Promise.reject(error);
    }
};

export default Endpoint;