 <template>
    <div class="fixed inset-x-0 bottom-0 px-3 py-3 pointer-events-none z-50">
        <div class="pointer-events-auto">
            <transition-group 
                mode="out-in"
                move-class="transform transition-all duration-300"
                enter-active-class="transform transition-all duration-300 ease-out"
                leave-active-class="transform transition-all duration-300 ease-out absolute inset-x-0"
                enter-from-class="scale-75 opacity-0"
                enter-to-class="scale-100"
                leave-from-class="scale-100"
                leave-to-class="scale-75 opacity-0"
            >
                <div 
                    v-for="notification in notifications"
                    :key="notification.id"   
                    @click="remove(notification)"
                    class="mt-2 first:mt-0 px-4 py-2 bg-indigo-500 rounded-xl shadow-md z-50 transform transition-all duration-300 ease-out" 
                >
                    <p class="text-lg text-white font-semibold">{{ notification.title }}</p>
                    <p class="text-sm text-white">{{ notification.text }}</p>
                </div>
            </transition-group>
        </div>
        
    </div>
</template>

<script>
    import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
    import uuid from '@/utils/uuid.js';
    import emitter from '@/utils/emitter.js'
    
    export default defineComponent({
        name: 'Notifications',
        setup() {
            const notifications = ref([]);
            function add(props) {
                const notification = {
                    id: uuid(),
                    duration: 3000,
                    ...props,
                };

                if (notification.duration >= 0) {
                    notification.timer = setTimeout(() => {
                        remove(notification)
                    }, notification.duration);
                }
                notifications.value.push(notification);
            }
            function remove(notification) {
                clearTimeout(notification.timer);
                const index = notifications.value.findIndex((item) => item.id === notification.id);
                if (index >= 0) {
                    notifications.value.splice(index, 1);
                }
            }
            onMounted(() => {
                function handler(notification) {
                    add(notification);
                };
                emitter.on('notifications:add', handler);
                onUnmounted(() => {
                    emitter.off('notifications:add', handler);
                });
            });
            
            return {
                notifications,
                add,
                remove
            };
        }
    });
</script>