const getLocal = (name) => {
    const match = localStorage.getItem(name);
    return match ? JSON.parse(match) : null;
};

const setLocal = (name, value) => {
    localStorage.setItem(name, JSON.stringify(value));
};

const removeLocal = (name) => {
    localStorage.removeItem(name);
};

export default (state, options={}) => {
    const namespace = options.namespace || '';
    const attrs = options.attrs || Object.keys(state);

    for (let attr of attrs) {
        const key = namespace ? `${namespace}-${attr}` : attr;
        const defaultValue = state[attr];

        Object.defineProperty(state, attr, {
            get() {
                return getLocal(key) || defaultValue;
            },
            set(value) {
                if(value === null) {
                    return removeLocal(key);
                }

                return setLocal(key, value);
            }
        });
    }
}