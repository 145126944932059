import Alerts from './Alerts.vue';
import emitter from '@/utils//emitter.js';

function alert(n) {
    emitter.emit('alerts:add', n);
}

export {
    Alerts,
    alert
};